import React, { useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { uid } from "uid"

import { useGlobalContext } from "../../context/layout-context"
import { InternalPromotionImpression } from "../../../utils/gtm"

const ImageContentful = dynamic(() => import("../contentful/image"), {
  ssr: false,
})
const TitleUnderlined = dynamic(() => import("../title/title-underlined"), {
  ssr: true,
})
const Title = dynamic(() => import("../title/title"), { ssr: true })
const TextContent = dynamic(() => import("../contentful/text-content"), {
  ssr: true,
})
const Shapes = dynamic(() => import("../bg-pattern/shapes"), { ssr: false })
const Patterns = dynamic(() => import("../bg-pattern/patterns"), { ssr: false })
const IconType = dynamic(() => import("../icons/icons-group"), { ssr: false })
const ButtonTriangleTracking = dynamic(
  () => import("../buttons/button-triangle-tracking"),
  { ssr: false }
)
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})
const VideoLoopPlayer = dynamic(() => import("../video/video-loop-player"), {
  ssr: false,
})
const PlayVideo = dynamic(() => import("../buttons/play-video"), { ssr: false })
const VideoBlockPlayer = dynamic(() => import("../video/video-block-player"), {
  ssr: false,
})
const IconClose = dynamic(() => import("../icons/icon-close"), { ssr: false })

function Promotion({
  grap,
  classList,
  title,
  content,
  staticContent,
  blockWidthClasses,
  containerClassList,
  buttons,
  cover,
  titleUnderlined,
  shape,
  shapeClassList,
  pattern,
  background,
  patternClassList,
  titleTag,
  titleClassList,
  contentWidthClasses,
  imageWidthClasses,
  alignContent,
  lineWidth,
  classColumnsContent,
  flame,
  maxWidthContent,
  widthDesktopImg,
  heightDesktopImg,
  heightMobileImg,
  widthMobileImg,
  layoutImgDesktop,
  layoutImgMobile,
  imageClassList,
  coverPosition,
  backgroundSize,
  fontWeight,
  titleAlign,
  textContentAlign,
  giftCards,
  classesContent,
  flameHome,
  paddingContentHome,
  triangleHome,
  marginLeftHome,
  imageCamera,
  havePaddingBlock,
  padding,
  promoId,
  id,
  source,
  videoLoopId,
  videoLoopFile,
  videoLoopSource,
  popupPlayer,
  contentClasses,
  backgroundColor,
}) {
  const { globalState } = useGlobalContext()
  const { isDesktop } = globalState || {}

  const [loopPlaying, setLoopPlaying] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)

  let textAlign
  let buttonsAlign
  let alignBox
  let contentPosition
  let imagePosition
  let paddingContent
  let coverSize

  switch (alignContent) {
    case "Center":
      textAlign = "text-center"
      alignBox = "mx-auto"
      buttonsAlign = "justify-center"
      break
    case "Center Mobile":
      textAlign = `text-center lg:text-left ${marginLeftHome}`
      alignBox = "mx-auto lg:mx-0"
      buttonsAlign = "justify-center lg:justify-start"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      alignBox = "mx-0 lg:mx-auto"
      buttonsAlign = "justify-start lg:justify-center"
      break
    case "Left":
      textAlign = `text-left ${marginLeftHome}`
      alignBox = "mx-0"
      buttonsAlign = "justify-start"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      alignBox = "ml-auto mr-0 lg:ml-0 lg:mr-auto"
      buttonsAlign = "justify-center"
      break
    default:
      textAlign = "text-left"
      alignBox = "mx-0"
      buttonsAlign = "justify-start"
  }

  switch (coverPosition) {
    case "Left":
      contentPosition = "lg:flex-row-reverse"
      imagePosition = "left-0 right-1/2"
      paddingContent = "lg:pl-15"
      break
    case "Right":
      contentPosition = "lg:flex-row"
      imagePosition = "right-0 left-1/2"
      paddingContent = "lg:pr-15"
      break
    default:
      contentPosition = "lg:flex-row-reverse"
      imagePosition = "left-0 right-1/2"
      paddingContent = "lg:pl-15"
  }

  switch (backgroundSize) {
    case "Background Cover":
      coverSize = "object-cover"
      break
    case "Background Contain":
      coverSize = "object-contain"
      break
    default:
      coverSize = "object-cover"
  }

  useEffect(() => {
    if (promoId) {
      const page = document ? document.title : ""
      InternalPromotionImpression(promoId, title, page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <article
      className={`overflow-hidden w-full ${grap ? "pt-15" : "pt-0"}`}
      data-layer-component="promotion"
    >
      <div
        className={`container px-0 lg:px-15 xl:px-30 max-w-full relative overflow-hidden ${
          havePaddingBlock ? padding : ""
        } ${classList} ${blockWidthClasses || "w-full"}`}
        style={{ backgroundColor: backgroundColor || "#FFFFFF" }}
      >
        {videoPlaying && isDesktop ? (
          // Video Embeded
          <div
            className={`${
              imageWidthClasses || "w-1/2"
            } absolute inset-0 ${imagePosition} z-1`}
          >
            <VideoBlockPlayer
              id={id}
              source={source}
              videoPlaying={videoPlaying}
            />
            <button
              type="button"
              onClick={() => setVideoPlaying(false)}
              className="absolute top-4 right-4"
            >
              <IconClose classList="text-white w-8 h-8" />
            </button>
          </div>
        ) : (
          <>
            {/* Promo Image */}
            {cover && !loopPlaying ? (
              <React.StrictMode>
                {isDesktop ? (
                  <div
                    className={`${
                      imageWidthClasses || "w-1/2"
                    } absolute inset-0 ${imagePosition} z-1`}
                  >
                    <ImageContentful
                      sizes="80vw"
                      src={cover.image.url || null}
                      width={widthDesktopImg}
                      height={heightDesktopImg}
                      title={cover.captionAlt || cover.title || cover.fileName}
                      layout={layoutImgDesktop}
                      classList={`absolute inset-0 object-center ${imageClassList} ${coverSize}`}
                    />
                  </div>
                ) : null}
              </React.StrictMode>
            ) : null}

            {(isDesktop && videoLoopId && videoLoopSource) ||
            (isDesktop && videoLoopFile && videoLoopSource) ? (
              <VideoLoopPlayer
                id={videoLoopId || videoLoopFile}
                source={videoLoopSource}
                classList={`${imagePosition} z-1`}
                setLoopPlaying={setLoopPlaying}
                poster={cover.image.url || null}
              />
            ) : null}

            {popupPlayer ? (
              <React.StrictMode>
                {id && source && isDesktop ? (
                  <div
                    className={`absolute inset-0 flex items-center justify-center ${imagePosition} z-1`}
                  >
                    <ButtonPlayVideo
                      id={id}
                      source={source}
                      buttonClassList="w-20 h-20 text-white"
                    />
                  </div>
                ) : null}
              </React.StrictMode>
            ) : (
              <React.StrictMode>
                {id && source && isDesktop ? (
                  <div
                    className={`absolute inset-0 flex items-center justify-center ${imagePosition} z-1`}
                  >
                    <PlayVideo
                      id={id}
                      source={source}
                      setVideoPlaying={setVideoPlaying}
                      buttonClassList="w-20 h-20 text-white"
                    />
                  </div>
                ) : null}
              </React.StrictMode>
            )}
          </>
        )}

        {shape ? <Shapes classList={`absolute ${shapeClassList}`} /> : null}
        <div className="relative flex h-full">
          {triangleHome && (
            <svg
              viewBox="0 0 147 184"
              className="fill-current hidden lg:block absolute top-1/2 transform -translate-y-1/2 -translate-x-5 h-10/12 lg:h-48 z-1 w-full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.26404e-06 92L146.25 0.201311L146.25 183.799L1.26404e-06 92Z"
                fill="#FFCD41"
              />
            </svg>
          )}
          {imageCamera ? (
            <svg
              viewBox="0 0 142 111"
              className="fill-current left-0 lg:-left-32 lg:block absolute -top-16 lg:top-32  h-24 lg:h-24 z-1 w-full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2:563)">
                <path
                  d="M52.6619 26.3716C52.6619 31.5874 51.1176 36.686 48.2243 41.0228C45.3311 45.3596 41.2187 48.7397 36.4074 50.7357C31.596 52.7317 26.3017 53.254 21.194 52.2364C16.0863 51.2189 11.3946 48.7072 7.71214 45.0191C4.02968 41.3309 1.52191 36.632 0.505923 31.5164C-0.510064 26.4008 0.0113807 21.0984 2.00431 16.2796C3.99724 11.4608 7.37215 7.34216 11.7023 4.44441C16.0324 1.54667 21.1232 0 26.3309 0C33.3144 0 40.0117 2.77842 44.9498 7.72405C49.8878 12.6697 52.6619 19.3774 52.6619 26.3716Z"
                  fill="#FCE131"
                />
                <path
                  d="M117.333 29.0913L18.0047 39.793L23.9515 95.1593L123.28 84.4576L117.333 29.0913Z"
                  fill="#EE3561"
                />
                <path
                  d="M142.012 84.6284C142.012 89.8445 140.467 94.9434 137.574 99.2803C134.68 103.617 130.568 106.997 125.756 108.993C120.944 110.989 115.65 111.511 110.542 110.493C105.434 109.475 100.742 106.963 97.0599 103.274C93.3777 99.5851 90.8704 94.8856 89.8551 89.7696C88.8398 84.6537 89.362 79.351 91.3559 74.5324C93.3497 69.7137 96.7255 65.5954 101.056 62.6984C105.387 59.8013 110.479 58.2557 115.687 58.2568C122.669 58.2584 129.365 61.0375 134.302 65.983C139.238 70.9284 142.012 77.6353 142.012 84.6284Z"
                  fill="#FCE131"
                />
                <path
                  d="M115.675 58.2568C117.301 58.2608 118.923 58.4128 120.521 58.7109L123.288 84.4456L89.5736 88.0841C89.4225 86.9384 89.3458 85.784 89.3439 84.6284C89.3439 77.6342 92.1181 70.9265 97.0561 65.9809C101.994 61.0353 108.692 58.2568 115.675 58.2568ZM50.7484 36.2609L18.0172 39.7991L19.3066 51.7937C25.5374 53.5263 32.1871 52.9004 37.9865 50.0354C43.7859 47.1704 48.3289 42.2669 50.7484 36.2609Z"
                  fill="#5FC4BA"
                />
                <path
                  d="M70.6497 81.0194C81.0263 81.0194 89.4382 72.5946 89.4382 62.202C89.4382 51.8094 81.0263 43.3845 70.6497 43.3845C60.2731 43.3845 51.8612 51.8094 51.8612 62.202C51.8612 72.5946 60.2731 81.0194 70.6497 81.0194Z"
                  stroke="#FCE131"
                  strokeWidth="6.87"
                  strokeMiterlimit="10"
                  fill="#EE3561"
                />
                <path
                  d="M19.3773 36.3907C20.6138 36.2485 21.827 35.9472 22.9866 35.4943C23.5925 35.2756 24.1827 35.0155 24.753 34.7159L25.0238 34.5744C25.2538 34.4599 25.4778 34.334 25.6951 34.197C26.3239 33.8424 26.9157 33.4256 27.4615 32.9527L27.8324 32.6343C27.9949 32.5053 28.1502 32.3675 28.2975 32.2215C29.0056 31.5789 29.6486 30.8678 30.217 30.0985L31.1179 28.7953C31.7912 27.779 32.3819 26.7101 32.8843 25.5991C33.5049 24.2128 33.8871 22.7315 34.0147 21.2176C34.0735 20.3237 33.9513 19.4271 33.6556 18.5816C33.2635 17.7011 32.6825 16.9179 31.954 16.2877C31.7832 16.1049 31.636 15.9338 31.5006 15.7628C31.3809 15.6375 31.2924 15.4857 31.2423 15.3197C31.1921 15.1537 31.1818 14.9783 31.2121 14.8075C31.2294 14.7405 31.2531 14.6754 31.2827 14.6129V14.5716C31.5061 14.0066 31.9238 13.5402 32.4603 13.2566C32.4851 13.2383 32.505 13.2143 32.5184 13.1865C32.5317 13.1588 32.5381 13.1281 32.5369 13.0974C32.5358 13.0749 32.5293 13.053 32.518 13.0335C32.5068 13.014 32.491 12.9975 32.4721 12.9853L31.8362 12.4959C31.8043 12.4746 31.7681 12.4605 31.7302 12.4546C31.19 12.47 30.6761 12.6915 30.2936 13.0738C29.914 13.4509 29.5979 13.887 29.3574 14.3652C29.2604 14.4908 29.1377 14.5942 28.9976 14.6684C28.8574 14.7427 28.7031 14.7861 28.5448 14.7957L28.0444 14.8488H27.7794C27.5733 14.8488 26.8315 14.9019 26.7431 14.9137H26.6607C26.426 14.9493 26.1939 15.0005 25.9659 15.067C25.7775 15.1142 23.6107 15.7333 22.6981 18.7703C22.505 19.4493 22.3866 20.1473 22.3448 20.852C22.3448 20.9581 22.3036 21.0702 22.28 21.1881C22.1976 24.461 20.9729 30.8828 16.9161 34.2501C16.6871 34.4525 16.5346 34.7277 16.4842 35.0294C16.4338 35.3312 16.4886 35.6411 16.6394 35.9071C16.7382 36.0496 16.8647 36.1707 17.0115 36.2629C17.1582 36.3551 17.322 36.4166 17.4931 36.4438C18.0946 36.5542 18.7111 36.5542 19.3125 36.4438"
                  fill="#EE3561"
                />
                <path
                  d="M124.589 93.5035C123.375 93.7764 122.13 93.8933 120.886 93.8514C120.241 93.844 119.598 93.7927 118.96 93.6981L118.666 93.6509C118.377 93.6096 118.077 93.5684 117.901 93.5212C117.192 93.395 116.497 93.1974 115.828 92.9315C115.693 92.8725 115.534 92.8135 115.375 92.7546C115.216 92.6956 114.915 92.5777 114.786 92.5187C113.907 92.1429 113.067 91.6827 112.278 91.1447L110.994 90.2129C110.031 89.4751 109.131 88.6585 108.303 87.7716C107.259 86.6679 106.407 85.396 105.783 84.0093V83.9798C105.432 83.1561 105.253 82.2689 105.259 81.3733C105.333 80.4128 105.623 79.4815 106.107 78.6489C106.207 78.4189 106.29 78.2066 106.36 78.0002C106.432 77.8426 106.466 77.6702 106.459 77.4969C106.452 77.3237 106.404 77.1546 106.319 77.0036C106.28 76.9435 106.235 76.8881 106.184 76.8385C106.177 76.8259 106.167 76.8156 106.154 76.809C105.747 76.3514 105.193 76.051 104.588 75.9598H104.553C104.523 75.9512 104.497 75.9353 104.475 75.9137C104.453 75.8921 104.438 75.8653 104.429 75.836C104.419 75.8159 104.413 75.7937 104.413 75.7711C104.413 75.7485 104.419 75.7263 104.429 75.7062L104.87 75.034C104.892 75.0041 104.92 74.9799 104.953 74.9632C105.47 74.7978 106.031 74.8379 106.519 75.0753C107.001 75.3028 107.44 75.6098 107.82 75.9834C107.955 76.0687 108.105 76.1248 108.262 76.1481C108.42 76.1715 108.58 76.1616 108.733 76.119L109.222 76.007L109.457 75.9185C109.646 75.836 110.37 75.6591 110.452 75.6355H110.505H110.541C110.774 75.5905 111.01 75.561 111.247 75.547C111.441 75.547 113.702 75.3937 115.551 77.9648C115.959 78.5421 116.3 79.1632 116.57 79.8165L116.741 80.1054C117.918 83.166 121.168 88.8271 126.103 90.667C126.387 90.7807 126.623 90.99 126.77 91.259C126.917 91.528 126.967 91.84 126.909 92.1413C126.863 92.3071 126.783 92.4619 126.676 92.5966C126.569 92.7313 126.436 92.8431 126.285 92.9256C125.752 93.2231 125.169 93.4228 124.566 93.5153"
                  fill="#EE3561"
                />
                <path
                  d="M116.764 80.0759L116.593 79.787C116.324 79.1324 115.98 78.5111 115.569 77.9353C113.726 75.3642 111.447 75.4998 111.271 75.5175C111.034 75.5315 110.798 75.5611 110.564 75.606H110.529H110.476C110.393 75.606 109.669 75.8065 109.481 75.889L109.245 75.9775L108.757 76.0895C108.603 76.1321 108.443 76.142 108.286 76.1187C108.129 76.0953 107.978 76.0392 107.844 75.9539C107.457 75.5638 107.004 75.2445 106.507 75.0104C106.02 74.7757 105.462 74.7357 104.947 74.8983C104.914 74.915 104.886 74.9393 104.865 74.9691L104.423 75.6414C104.413 75.6614 104.407 75.6837 104.407 75.7062C104.407 75.7288 104.413 75.7511 104.423 75.7711C104.432 75.8005 104.447 75.8272 104.469 75.8488C104.491 75.8705 104.517 75.8863 104.547 75.8949H104.582C105.173 76.0062 105.708 76.3187 106.095 76.7795C106.108 76.7861 106.118 76.7964 106.125 76.809C106.176 76.8586 106.222 76.914 106.26 76.9741C106.345 77.1251 106.393 77.2942 106.4 77.4674C106.407 77.6407 106.374 77.8131 106.301 77.9707C106.231 78.1771 106.148 78.3894 106.048 78.6194C105.564 79.452 105.274 80.3833 105.2 81.3438C105.195 82.2394 105.373 83.1267 105.724 83.9503V83.9798C106.06 84.7571 106.481 85.4948 106.978 86.1794L119.29 84.8525C118.246 83.3602 117.382 81.7489 116.717 80.0524"
                  fill="#FCE131"
                />
              </g>
              <defs>
                <clipPath id="clip0_2:563">
                  <rect width="142" height="111" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : null}
          <div
            className={`${containerClassList} absolute inset-y-0 -inset-x-30`}
          />
          {pattern ? (
            <Patterns
              background={`${background || "chillis"}`}
              classList={`absolute ${patternClassList}`}
            />
          ) : null}
          <div
            className={`relative flex items-stretch w-full flex-wrap ${classColumnsContent} ${contentPosition} max-w-1400px mx-auto z-1`}
          >
            <div
              className={`relative ${
                contentWidthClasses || "w-full lg:w-1/2"
              } ${
                giftCards ? "py-10" : "py-20 lg:py-40"
              } flex items-center px-8 lg:px-0 ${
                paddingContentHome || paddingContent
              }`}
            >
              {flame ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current absolute h-10/12 lg:h-11/12 -left-5/12 lg:-left-8/12 lg:inset-y-4 right-auto text-orange-500 w-full"
                  viewBox="0 0 248 425"
                >
                  <path d="M32.82 178.92c-78.56 126.47-6.37 244.1 134.63 245.35a1.89 1.89 0 0 0 1.16-3.42c-12.67-8.47-33.05-28.46-1-55.6 36.23-30.58 69.91-45.77 78.27-111.1 4.58-36.28-3.64-59.39-6.82-66-.38-.77-1.7-.95-2.09-.19-4.03 7.05-19.73 31.81-43.31 39.92-.57.2-1.14-.37-.76-1.13 8.63-15.04 16.38-41.66 17.9-74.12C213.8 93.02 183.49 20.7 93.84.16c-.95-.18-1.52.77-.77 1.53 29.8 30.94 9.19 83.63-3.25 100.98-22.19 29.73-41.86 51.67-57 76.25Z" />
                </svg>
              ) : null}
              {flameHome && isDesktop ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current absolute lg:-mt-96 h-1/3 lg:h-48 text-red-200 w-full"
                  viewBox="0 0 248 425"
                >
                  <path d="M32.82 178.92c-78.56 126.47-6.37 244.1 134.63 245.35a1.89 1.89 0 0 0 1.16-3.42c-12.67-8.47-33.05-28.46-1-55.6 36.23-30.58 69.91-45.77 78.27-111.1 4.58-36.28-3.64-59.39-6.82-66-.38-.77-1.7-.95-2.09-.19-4.03 7.05-19.73 31.81-43.31 39.92-.57.2-1.14-.37-.76-1.13 8.63-15.04 16.38-41.66 17.9-74.12C213.8 93.02 183.49 20.7 93.84.16c-.95-.18-1.52.77-.77 1.53 29.8 30.94 9.19 83.63-3.25 100.98-22.19 29.73-41.86 51.67-57 76.25Z" />
                </svg>
              ) : null}
              {flameHome && !isDesktop ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current absolute top-9 left-0 lg:pr-15 lg:-mt-96 h-1/3 lg:h-48 text-red-200 w-full"
                  viewBox="0 0 248 425"
                >
                  <path d="M32.82 178.92c-78.56 126.47-6.37 244.1 134.63 245.35a1.89 1.89 0 0 0 1.16-3.42c-12.67-8.47-33.05-28.46-1-55.6 36.23-30.58 69.91-45.77 78.27-111.1 4.58-36.28-3.64-59.39-6.82-66-.38-.77-1.7-.95-2.09-.19-4.03 7.05-19.73 31.81-43.31 39.92-.57.2-1.14-.37-.76-1.13 8.63-15.04 16.38-41.66 17.9-74.12C213.8 93.02 183.49 20.7 93.84.16c-.95-.18-1.52.77-.77 1.53 29.8 30.94 9.19 83.63-3.25 100.98-22.19 29.73-41.86 51.67-57 76.25Z" />
                </svg>
              ) : null}
              <div
                className={`relative ${textAlign} w-full ${alignBox} ${
                  maxWidthContent ? "max-w-sm" : "max-w-full"
                } ${contentClasses}`}
              >
                {titleUnderlined ? (
                  <TitleUnderlined
                    tag={titleTag}
                    classList={titleClassList}
                    content={title}
                    colorTitle="text-black"
                    lineWidth={lineWidth}
                    titleAlign={titleAlign || alignContent}
                  />
                ) : (
                  <Title
                    tag={titleTag}
                    classList={titleClassList}
                    content={title}
                    colorTitle="text-black"
                    rotated
                    titleAlign={titleAlign || alignContent}
                  />
                )}
                {content && (
                  <div className={`max-w-sm ${alignBox}`}>
                    <TextContent
                      content={content}
                      fontWeight={fontWeight}
                      contentAlign={textContentAlign}
                      classes={classesContent}
                    />
                  </div>
                )}
                {staticContent && <p>{staticContent}</p>}
                {buttons ? (
                  <React.StrictMode>
                    {buttons && buttons.length > 0 ? (
                      <div
                        className={`${buttonsAlign} btn-container items-center mt-0`}
                      >
                        {buttons.map(button => (
                          <React.Fragment key={uid(16)}>
                            {button.icon ? (
                              <React.StrictMode>
                                {button.externalUrl ||
                                button.externalUrl !== null ? (
                                  <div className="w-1/2 pl-5 mt-3">
                                    <IconType
                                      type={button.icon.title}
                                      classList="w-full max-w-150px h-16"
                                      url={button.externalUrl}
                                      openInNewTab={button.openInNewTab}
                                      trackingEvent="PromotionClick"
                                      trackingName={title}
                                      trackingPromoId={promoId}
                                      color={button.buttonColor}
                                    />
                                  </div>
                                ) : null}
                              </React.StrictMode>
                            ) : (
                              <React.StrictMode>
                                {button.externalUrl ||
                                button.externalUrl !== null ? (
                                  <ButtonTriangleTracking
                                    triangle
                                    key={uid(16)}
                                    href={button.externalUrl}
                                    openInNewTab={button.openInNewTab}
                                    classList="btn-yellow -mt-8"
                                    trackingEvent="PromotionClick"
                                    trackingName={title}
                                    trackingPromoId={promoId}
                                    color={button.buttonColor}
                                  >
                                    {button.label}
                                  </ButtonTriangleTracking>
                                ) : null}
                              </React.StrictMode>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <div className={`${buttonsAlign} btn-container mt-0`}>
                        {buttons.icon ? (
                          <React.StrictMode>
                            {buttons.externalUrl &&
                            buttons.externalUrl !== null ? (
                              <IconType
                                type={buttons.icon.title}
                                classList="w-180px h-16 pl-5 lg:pl-9"
                                url={buttons.externalUrl}
                                openInNewTab={buttons.openInNewTab}
                                trackingEvent="PromotionClick"
                                trackingName={title}
                                trackingPromoId={promoId}
                                color={buttons.buttonColor}
                              />
                            ) : null}
                          </React.StrictMode>
                        ) : (
                          <React.StrictMode>
                            {buttons.externalUrl &&
                            buttons.externalUrl !== null ? (
                              <ButtonTriangleTracking
                                triangle
                                key={uid(16)}
                                href={buttons.externalUrl}
                                openInNewTab={buttons.openInNewTab}
                                classList="btn-yellow -mt-8"
                                trackingEvent="PromotionClick"
                                trackingName={title}
                                trackingPromoId={promoId}
                                color={buttons.buttonColor}
                              >
                                {buttons.label}
                              </ButtonTriangleTracking>
                            ) : null}
                          </React.StrictMode>
                        )}
                      </div>
                    )}
                  </React.StrictMode>
                ) : null}
              </div>
            </div>

            {/* Promo Image */}
            {cover ? (
              <React.StrictMode>
                {!isDesktop ? (
                  // Video Embeded
                  <React.StrictMode>
                    {videoPlaying ? (
                      <div className="w-full relative block pb-56pc overflow-hidden">
                        <VideoBlockPlayer
                          id={id}
                          source={source}
                          videoPlaying={videoPlaying}
                        />
                        <button
                          type="button"
                          onClick={() => setVideoPlaying(false)}
                          className="absolute top-4 right-4"
                        >
                          <IconClose classList="text-white w-8 h-8" />
                        </button>
                      </div>
                    ) : (
                      <div className="w-full relative block">
                        {!loopPlaying ? (
                          <React.StrictMode>
                            {cover.mobileImage ? (
                              <ImageContentful
                                sizes="70vw"
                                src={cover.mobileImage.url || null}
                                width={widthMobileImg}
                                height={heightMobileImg}
                                title={cover.mobileImage.captionAlt || cover.mobileImage.title || cover.mobileImage.fileName}
                                layout={layoutImgMobile}
                                classList={`absolute inset-0 ${imageClassList} ${coverSize}`}
                              />
                            ) : (
                              <ImageContentful
                                sizes="70vw"
                                src={cover.image.url || null}
                                width={widthMobileImg}
                                height={heightMobileImg}
                                title={cover.captionAlt}
                                layout={layoutImgMobile}
                                classList={`absolute inset-0 ${imageClassList} ${coverSize}`}
                              />
                            )}
                          </React.StrictMode>
                        ) : null}

                        {(videoLoopId && videoLoopSource) ||
                        (videoLoopFile && videoLoopSource) ? (
                          <div className="block pb-9/12">
                            <VideoLoopPlayer
                              id={videoLoopId || videoLoopFile}
                              source={videoLoopSource}
                              setLoopPlaying={setLoopPlaying}
                            />
                          </div>
                        ) : null}

                        {popupPlayer ? (
                          <React.StrictMode>
                            {id && source ? (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <ButtonPlayVideo
                                  id={id}
                                  source={source}
                                  buttonClassList="w-20 h-20 text-white"
                                />
                              </div>
                            ) : null}
                          </React.StrictMode>
                        ) : (
                          <React.StrictMode>
                            {id && source ? (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <PlayVideo
                                  id={id}
                                  source={source}
                                  setVideoPlaying={setVideoPlaying}
                                  buttonClassList="w-20 h-20 text-white"
                                />
                              </div>
                            ) : null}
                          </React.StrictMode>
                        )}
                      </div>
                    )}
                  </React.StrictMode>
                ) : null}
              </React.StrictMode>
            ) : null}
          </div>
        </div>
      </div>
    </article>
  )
}

export default Promotion
